import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  padding: 13px 4.53vw;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 21px 4.53vw;
  }
  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 27px 4.53vw;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 35px 4.53vw;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 52px 4.53vw;
  }
  @media screen AND (min-width: ${breakpoints.desktopXL}px) {
    max-width: 1920px;
    margin: 0 auto;
    padding: 52px 87px;
  }
`;

const WrapperSmallEqTopBtm: React.FC = ({ children }) => (
  <WrapperDiv>{children}</WrapperDiv>
);

export default WrapperSmallEqTopBtm;
