import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import { Background } from "react-imgix";
import { imgix } from "../data/data";

interface FWImageProps {
  image: {
    url: string;
    title: string;
  };
}

const FWImageDiv = styled(Background)`
  height: 25vh;
  width: 100vw;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    height: 55vh;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: 100vh;
  }
`;

const FWImage: React.FC<FWImageProps> = ({ image }) => (
  <>
      {image && image.url && <FWImageDiv
      src={image.url || ''}
    imgixParams={imgix.fullImg}
    htmlAttributes={{ title: image.title }}
  /> }
  </>
);

export default FWImage;
