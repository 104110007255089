import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";
// new: import Model from "./Models/3K_Standart_Block_new";
import Model from "./Models/3Standardblock";
import styled from "styled-components";
//use this: https://gltf.pmnd.rs/

const StyledCanvas = styled(Canvas)`
  @media (min-width: 768px) {
    margin-top: -20vh;
  }
  height: 60vw !important;
  width:99%;
`;

const Model3D = () => {
  const [width, setWidth] = useState("99%");

  useEffect(() => {
    setTimeout(() => {
      setWidth("100%");
    }, 1000);
  }, []);

  const ref = useRef();

  return (
    <div style={{ cursor: "pointer" }}>
      {/** <Canvas>
        <Model />
      </Canvas>
       */}
      <StyledCanvas
        dpr={[1, 2]}
        camera={{ fov: 50 }}
        style={{width: width }}
      >
        <ambientLight intensity={0.2} />
        <Suspense fallback={null}>
          <Stage controls={ref} environment={null}>
            <Model />
          </Stage>
        </Suspense>
        <OrbitControls enableZoom={false} ref={ref} autoRotate />
        threecontent
      </StyledCanvas>
    </div>
  );
};

export default Model3D;
