import React from "react";

const MainVideo: React.FC = () => (
  <div>
    <video autoPlay loop muted style={{ width: "100%" }}>
      <source src="/assets/movie/SwissBlock_Systemsteine_Animation.mp4" type="video/mp4" />
      Ihr Browser unterstützt das Video-Tag nicht.
    </video>
  </div>
);

export default MainVideo;
